textarea,
input {
	color: $input-color;

	&:focus,
	&:active {
		outline: none;
		box-shadow: none;
	}

	@include placeholder {
		color: $input-placeholder-color;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

input[type="search"] {
	&::-webkit-search-cancel-button {
		appearance: none;
		background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%23ffffff" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>');
		width: 0.75rem;
		height: 0.75rem;
		background-size: 0.75rem;
		cursor: pointer;
	}
}

textarea {
	resize: none !important;
	@extend .scrollbar;
}

textarea.form-control {
	min-height: 4rem !important;
}

div.form-control {
	input {
		border: 0;
		background-color: transparent;
	}
}

.form-check-input {
	flex-shrink: 0;
	margin-top: 0;
}

.form-check {
	.form-check-input {
		margin-top: 1px;
	}
}

.form-switch {
	.form-check-input {
		@include visually-hidden();

		&:checked {
			& ~ .form-check-label {
				&:before {
					background-color: rgba($white, 0.15);
				}

				&:after {
					transform: translateX(17px);
					background-color: $white;
				}
			}
		}

		&:not(:disabled) {
			&:focus {
				& ~ .form-check-label {
					&:before {
						box-shadow: $input-focus-box-shadow;
					}
				}
			}
		}
	}

	.form-check-label {
		position: relative;
		display: flex;
		align-items: center;
		gap: 0.75rem;
		line-height: 1.3;

		&:before,
		&:after {
			content: "";
		}

		&:before {
			width: 35px;
			height: 18px;
			border: 1px solid rgba($white, 0.15);
			background-color: rgba($white, 0.1);
			display: inline-block;
			vertical-align: middle;
			border-radius: $border-radius-pill;
			transition:
				border-color 250ms,
				background-color 250ms;
		}

		&:after {
			position: absolute;
			left: 3px;
			top: 3px;
			height: 12px;
			width: 12px;
			background-color: $secondary-color;
			border-radius: 50%;
			transition:
				transform 250ms,
				background-color 250ms;
		}
	}
}

.input-group {
	.btn {
		--bs-btn-border-color: #{$input-border-color};
		--bs-btn-hover-border-color: #{$input-border-color};
		--bs-btn-hover-bg: #{$component-hover-inverse-bg};
	}
}

.valid-tooltip,
.invalid-tooltip {
	right: 0;
}
