// Icon wrapper
.icon {
	min-width: 2rem;
	height: 2rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	display: inline-flex;
	transition:
		color 300ms,
		background-color 300ms,
		box-shadow 300ms;
	color: $emphasis-color;

	&.ph,
	& > .ph {
		&:not([class*="fs-"]) {
			font-size: 1.15rem;
		}
	}

	&:hover,
	&.show {
		background: $component-hover-bg;
	}
}

// Links
.link-body {
	color: $text-color;

	&:hover {
		color: $emphasis-color;
	}
}

.link-muted {
	color: $secondary-color;

	&:hover {
		color: $text-color;
	}
}

// Masonry layout
.masonry {
	column-count: 3;

	@include media-breakpoint-down(xxl) {
		column-count: 2;
	}

	@include media-breakpoint-down(lg) {
		column-count: 1;
	}

	.card {
		display: inline-block;
		width: 100%;
		margin-bottom: 0.75rem;
	}
}

// Scrollbar
.scrollbar {
	@include scrollbar();
}

.hide-scrollbar {
	@include hide-scrollbar();
}

// Backdrop
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	transition: opacity 500ms;
	z-index: $sidebar-z-index - 1;
	background-color: $component-hover-bg;
	backdrop-filter: blur(0.25rem);
}

// Action
.bg-highlight {
	background-color: $component-hover-bg;
}

.bg-highlight-inverse {
	background-color: rgba($white, 0.1);
}

.bg-active {
	background: rgba($black, 0.3);
}

.bg-hover {
	&:hover {
		background-color: $component-hover-bg;
	}
}

.bg-hover-inverse {
	&:hover {
		background-color: rgba($white, 0.1);
	}
}

.bg-hover-emphasis {
	&:hover {
		background-color: rgba($white, 0.075);
	}
}

// Page loader
#page-loader {
	position: fixed;
	inset: 0;
    background-color: rgba($black, 0.75);
    z-index: 999999;
    align-items: center;
    justify-content: center;
    display: flex;
	backdrop-filter: blur(0.5rem);
	
	.spinner-border {
		color: #81223a;
	}
}
