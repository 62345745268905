[data-simplebar] {
	--simplebar-bg: #{rgba($black, 0.35)};

	height: 100%;
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;

	@include hide-scrollbar();

	.simplebar-track {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		&.simplebar-vertical {
			width: 0.55rem;
		}

		&.simplebar-horizontal {
			height: 0.55rem;
		}
	}

	.simplebar-scrollbar {
		&:before {
			background-color: var(--simplebar-bg);
		}
	}

	&:hover {
		.simplebar-scrollbar {
			&:before {
				opacity: 1;
			}
		}
	}
}

.modal,
.dropdown-menu,
.popover  {
	[data-simplebar] {
		--simplebar-bg:#{rgba($white, 0.2)}
	}
}