.alert-dismissible {
	.btn-close {
		background-image: none;
		color: currentColor;
		display: grid;
		place-content: center;
		margin-top: 1px;

		&::before {
			@include font-icon("\E4F8", 1.25rem);
		}
	}
}

.alert-link {
	text-decoration: underline;
	text-underline-offset: 0.25rem;
	text-decoration-thickness: 0.5px;
	text-decoration-style: dotted;
}

.alert-primary {
	--bs-alert-bg: #{rgba($blue, 0.75)};
	--bs-alert-color: #{$white};
	--bs-alert-link-color: #{$white};
}

.alert-secondary {
	--bs-alert-bg: #{rgba($secondary, 0.2)};
	--bs-alert-color: #{$white};
	--bs-alert-link-color: #{$white};
}

.alert-success {
	--bs-alert-bg: #{rgba($green, 0.75)};
	--bs-alert-color: #{$white};
	--bs-alert-link-color: #{$white};
}

.alert-info {
	--bs-alert-bg: #{rgba($cyan, 0.75)};
	--bs-alert-color: #{$white};
	--bs-alert-link-color: #{$white};
}

.alert-warning {
	--bs-alert-bg: #{rgba($orange, 0.75)};
	--bs-alert-color: #{$white};
	--bs-alert-link-color: #{$white};
}

.alert-danger {
	--bs-alert-bg: #{rgba($red, 0.75)};
	--bs-alert-color: #{$white};
	--bs-alert-link-color: #{$white};
}

.alert-light {
	--bs-alert-bg: #{rgba($white, 0.5)};
	--bs-alert-color: #{$black};
	--bs-alert-link-color: #{$black};
}

.alert-dark {
	--bs-alert-bg: #{rgba($black, 0.5)};
	--bs-alert-color: #{rgba($white, 0.75)};
	--bs-alert-link-color: #{$white};
}
