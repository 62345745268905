

$themes: (
	1: (#772036 #ffffff #ffe4e6 #ffc8cc rgba(#1A0A00, 0.4)),
	2: (#273c5b #ffffff #e0eaff #a6bccf rgba(#364d75, 0.75)),
	3: (#0d2b2d #cbf3f3 #92bbbb #638d8d rgba(#1A0A00, 0.4)),
	4: (#632144 #ffe4e5 #f8d9da #e7b7b8 rgba(#1A0A00, 0.4)),
	5: (#2d271d #fffbf6 #f7ecdf #f0dec8 rgba(#1A0A00, 0.4)),
	6: (#2d4f65 #e7feff #c6eced #a3d9db rgba(#1A0A00, 0.4)),
	7: (#33565a #e7fdff #ceebed #b7e4e8 rgba(#1A0A00, 0.4)),
	8: (#6f224b #ffe5f3 #f8cfe5 #ebafd0 rgba(#1A0A00, 0.4)),
);

@each $key, $value in $themes {
	$bodyBg: nth($value, 1);
	$titleColor: nth($value, 2);
	$bodyColor: nth($value, 3);
	$secondaryColor: nth($value, 4);
  	$scrollbarBg: nth($value, 5);

	[data-sa-theme="#{$key}"] {
		--bs-body-img: url(/src/img/theme/#{$key}.jpg);
		--bs-body-bg: #{$bodyBg};
		--bs-emphasis-color: #{$titleColor};
		--bs-secondary-color: #{$secondaryColor};
		--bs-body-color: #{$bodyColor};

		.dropdown-menu,
		.modal {
			--bs-secondary-color: #{rgba($secondaryColor, 0.75)};
		}
	}
}

#top-themes {
	& > button {
		&:before {
			content: "\E184";
			position: absolute;
			left: 1rem;
			top: 1rem;
			font-size: 2rem;
			font-family: $font-family-icon-light;
			opacity: 0;
			transform: scale3d(0.5, 0.5, 0.5);
			transition: opacity 300ms, transform 300ms;
		}

		&.active {
			&:before {
				opacity: 1;
				transform: scale3d(1, 1, 1);
			}
		}

		&:not(.active) {
			&:hover {
				opacity: 0.8;
			}
		}
	}
}