.toast {
   overflow: hidden;
   backdrop-filter: blur(0.5rem);

   .btn-close {
        width: 0.25rem;
        height: 0.25rem;
        margin-right: 0.1rem;
        flex-shrink: 0;
   }
}