.card {
	.card {
		&::before {
			content: "";
			inset: 0;
			position: absolute;
			z-index: -1;
			backdrop-filter: blur(0.75rem);
			background-color: $component-bg;
			border-radius: $border-radius;
			border: 1px solid var(--#{$prefix}component-border-color);
		}
	}
}

.card-full {
	min-height: 100%;
}

.card-title {
	font-weight: $font-weight-medium;
	margin-top: -0.1rem;

	&:not([class*="fs-"]) {
		font-size: $font-size-base;
	}
}

.card-subtitle {
	font-weight: normal;
	color: $text-color;
	line-height: 1.5;
	font-size: $font-size-base;
}

.card-header {
	.card-header-pills {
		gap: 1px;
		margin-left: -0.5rem;
	}
}

.card-hover {
	transition: background-color 200ms, border-color 200ms;

	&:hover {
		background-color: $component-active-bg;
	}
}
