.header {
	height: $header-height;
	z-index: $sidebar-z-index - 1;
	padding: 0 1rem 0 1.25rem;
	display: flex;
	align-items: center;
	background-color: $card-bg;
	flex-shrink: 0;
}

.logo {
	font-weight: $font-weight-medium;
	color: $emphasis-color;
	display: block;
	font-size: $font-size-lg;
	white-space: nowrap;
	flex-shrink: 0;

	@include media-breakpoint-up(lg) {
		width: $sidebar-width - 0.5rem;
	}
}

.header-menu {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	list-style: none;
	margin: 0;
	gap: 1rem;
	padding: 0;

	& > li {
		& > button {
			border: 0;
			background-color: transparent;
			color: $emphasis-color;
			font-size: 1.35rem;
			min-width: 2.5rem;
			min-height: 2.5rem;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color 300ms;

			&:hover,
			&.show {
				background: rgba($white, 0.15);
			}
		}

		.dropdown-menu {
			&.header-dropdown-menu {
				left: auto;
				top: $header-height - 0.5rem;
				right: 0.15rem;
				position: fixed;
				height: calc(100% - $header-height);
				max-width: 21rem;
				width: 100%;
				display: flex;
				flex-direction: column;
				opacity: 0;
				pointer-events: none;
				transform: translate3d(0, 0, 0);
				transition: opacity 300ms, transform 300ms;
				margin-top: 0;

				&.show {
					transform: translate3d(-1rem, 0, 0);
					opacity: 1;
					pointer-events: auto;
				}

				@include media-breakpoint-down(sm) {
					max-width: calc(100% - 1.5rem);
					height: calc(100% - 4.25rem);
					right: 0.75rem;
					top: $header-height + 1.25rem;

					&.show {
						transform: translate3d(0, -1.5rem, 0);
					}
				}
			}
		}
	}
}

.header-notify {
	position: relative;
	isolation: isolate;

	&:after {
		will-change: transform, border-color;
		content: "";
		position: absolute;
		width: 2.25rem;
		height: 2.25rem;
		border-radius: 50%;
		border: 2px solid transparent;
		z-index: 1;
		inset: 0;
		margin: auto;
		transform: scale3d(0, 0, 0);
		animation-iteration-count: infinite;
		animation-duration: 2000ms;
		backface-visibility: hidden;
		animation-name: notifyPulse;
		pointer-events: none;
	}
}

// Top navigation
@keyframes notifyPulse {
	0% {
		transform: scale3d(0, 0, 0);
		border-color: transparent;
	}

	50% {
		border-color: rgba($white, 0.75);
	}

	to {
		transform: scale3d(1, 1, 1);
		border-color: transparent;
	}
}