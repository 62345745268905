.icon-link {
	line-height: 1;
	height: 2rem;
	border-radius: $border-radius;
	padding: 0 0.6rem;
	color: $emphasis-color;
	transition: color 300ms, background-color 300ms, box-shadow 300ms;

	&.show {
		background: $component-hover-bg;
	}

	&:hover {
		background: $component-hover-bg;
	}

	& > .ph {
		&:not([class*="fs-"]) {
			font-size: 1.1rem;
		}
	}
}
